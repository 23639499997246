import { render, staticRenderFns } from "./Vila-Real-De-Santo-António.vue?vue&type=template&id=ef224442&scoped=true&"
import script from "./Vila-Real-De-Santo-António.vue?vue&type=script&lang=js&"
export * from "./Vila-Real-De-Santo-António.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef224442",
  null
  
)

export default component.exports